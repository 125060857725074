<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <div>
        <h1 class="text-xl text-left mr-4 font-extrabold ml-4 mt-3">
          {{ $route.query.name }}
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="px-3" v-else>
      <card class="p-5 mt-6">
        <div class="border rounded border-romanSilver border-dashed p-3">
          <p class="font-bold mb-5 text-lg">PAYE Information</p>
          <div class="flex w-full gap-3">
            <card class="p-3 w-auto">
              <h6 class="uppercase mb-2 font-bold mb-2 text-sm text-darkPurple">
                IRS
              </h6>
              <h6 class="font-semibold text-sm text-jet uppercase">
                {{ payeData.lirsName }}
              </h6>
            </card>
            <card class="p-3 ml-2">
              <div class="flex">
                <div class="flex flex-col">
                  <h6
                    class="font-semibold mt-2 text-xs font-black text-blueCrayola uppercase"
                  >
                    Employer Tax Id Number
                  </h6>
                  <span class="py-1 mr-4 capitalize font-semibold text-sm">
                    {{ payeData.taxNo }}
                  </span>
                </div>
              </div>
            </card>
            <card class="p-3 ml-2">
              <div class="flex">
                <div class="flex flex-col">
                  <h6
                    class="font-semibold mt-2 text-xs font-black text-blueCrayola uppercase"
                  >
                    Status
                  </h6>
                  <span
                    class="py-1 mr-4 capitalize font-semibold text-sm"
                    :class="
                      payeData.status === 'paid'
                        ? 'text-mediumSeaGreen'
                        : 'text-desire'
                    "
                  >
                    {{
                      payeData.status === "paid" ? "Remitted" : "Outstanding"
                    }}
                  </span>
                </div>
              </div>
            </card>
          </div>
        </div>

        <div class="border rounded border-romanSilver border-dashed p-3">
          <p class="font-bold mb-5 text-lg">Payrun Information</p>
          <div class="flex w-full gap-3">
            <card class="p-3 w-52">
              <h6 class="uppercase mb-2 font-bold mb-2 text-sm text-darkPurple">
                Pay run Type
              </h6>
              <h6 class="font-semibold text-sm text-jet uppercase">
                {{ payrunSummary.payrunType }}
              </h6>
            </card>
            <card class="p-3 ml-2">
              <h6 class="uppercase font-bold text-sm mb-2 text-blueCrayola">
                Pay Cycle Details
              </h6>
              <div class="flex">
                <div class="flex">
                  <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                    Pay Cycle:
                  </h6>
                  <span
                    class="px-2 py-1 ml-4 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{ payrunSummary.payCylce }}
                  </span>
                </div>

                <div class="flex">
                  <h6
                    class="font-semibold mt-2 ml-2 text-sm text-jet uppercase"
                  >
                    Pay frequency:
                  </h6>
                  <span
                    class="px-2 py-1 ml-4 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{
                      payrunSummary.payFrequency === "twice_monthly"
                        ? "Twice Monthly"
                        : payrunSummary.payFrequency === "weekly"
                        ? "Four Times Monthly"
                        : payrunSummary.payFrequency
                    }}
                  </span>
                </div>
                <div class="flex">
                  <h6
                    class="font-semibold text-sm mt-2 ml-2 text-jet uppercase"
                  >
                    Payment Schedule:
                  </h6>
                  <span
                    class="px-2 py-1 mx-4 text-blueCrayola capitalize font-semibold text-sm"
                    style="
                      background: rgba(33, 118, 255, 0.15);
                      border-radius: 5px;
                    "
                  >
                    {{ `${$getOrdinal(payrunSummary.payPosition)} Payment` }}
                  </span>
                </div>
              </div>
            </card>
            <card class="w-auto p-2">
              <p
                class="uppercase text-mediumSeaGreen mt-2 font-extrabold text-sm"
              >
                Categories
              </p>
              <p class="text-sm uppercase">
                {{ payrunSummary.categories }}
              </p>
            </card>
          </div>
        </div>

        <div class="flex justify-between mt-5">
          <div class="flex gap-5 flex-1">
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                Due Date
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ payrunSummary.dueDate }}
              </p>
            </card>
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                No. of Employees
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ payrunSummary.employeeNo }}
              </p>
            </card>
            <card class="w-auto p-3 pr-8">
              <p class="uppercase text-romanSilver font-extrabold text-xs">
                Total PAYE COST
              </p>
              <p class="text-lg text-darkPurple font-bold uppercase">
                {{ convertToCurrency(payrunSummary.totalPAYE) }}
              </p>
            </card>
          </div>
          <div class="flex items-center ml-4" v-if="payeData.status !== 'paid'">
            <Button
              class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              background-color="none"
              @click="validateRemit()"
            >
              Remit Offline
            </Button>

            <Button
              class="text-white mr-5 rounded"
              :background="appearance.buttonColor || defaultBTNColor"
              style-name="width:inherit"
              @click="isPayeOnlineRemit = true"
              v-if="remitPayeDisplay"
            >
              Remit Paye
            </Button>
          </div>
        </div>
        <div class="border w-full bg-romanSilver mt-8" />
      </card>
    </div>

    <div class="px-3" v-if="!loading">
      <card class="p-5 mt-6">
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width: 300px; margin-top: 0.1rem"
              />
            </template>
          </CardFooter>
        </template>
      </card>

      <div class="w-full mt-10">
        <sTable
          :headers="employeesHeader"
          :items="employeesData"
          aria-label="pension table"
          class="w-full mt-1"
          :has-checkbox="false"
          :has-number="false"
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.name" class="flex">
              <div>
                <img
                  class="mr-3"
                  :src="item.data.photo"
                  v-if="item.data.photo"
                  alt="user photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 30px; width: 30px; border-radius: 5px"
                  class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                  v-else
                >
                  {{ $getInitials(`${item.data.name}`) }}
                </div>
              </div>
              <div
                class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
              >
                <div class="flex flex-col">
                  <span class="text-darkPurple capitalize mr-3">
                    {{ item.data.name }}
                  </span>
                  <span class="text-romanSilver text-xs capitalize mr-3">
                    {{ item.data.designation }}
                  </span>
                </div>
              </div>
            </div>

            <div v-else-if="item.status">
              <Badge
                label="Remitted"
                variant="primary"
                background-color="rgba(19, 181, 106, 0.08)"
                color="#13B56A"
                style="
                  padding: 8px;
                  height: 33px;
                  font-weight: 600;
                  font-size: 14px;
                "
                v-if="item.data.status === 'paid'"
              />
              <Badge
                label="Outstanding"
                variant="primary"
                background-color="rgba(234, 60, 83, 0.08)"
                color="#EA3C53"
                style="
                  padding: 8px;
                  height: 33px;
                  font-weight: 600;
                  font-size: 14px;
                "
                v-else
              />
            </div>
          </template>
        </sTable>
      </div>
    </div>

     <ProceedActionModal v-if="isPayeOnlineRemit"
        @proceed="checkOption($event)"
        @cancel="isPayeOnlineRemit = false"
    />

    <Modal v-if="showProcessModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div>
          <h4 class="mb-6 text-romanSilver">
            You can remit pension offline through any other means. Providing
            details of your offline remittance helps the system properly account
            for your payroll costs.
          </h4>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="96px"
              @click="showProcessModal = false"
            >
              Cancel
            </Button>
            <Button class="ml-4 text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="proceed"
            >
              Proceed
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <RightSideBar
      v-if="showRemitForm"
      @close="showRemitForm = false"
      submit="Submit"
      @submit="handleSubmit"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Remit Offline</p>
      </template>
      <template v-slot:subtitle>
        <p class="text-base font-semibold mb-4">
          Providing details of your offline remittance helps the system properly
          account for your payroll costs.
        </p>
      </template>
      <p class="text-jet text-sm mr-5">Effective Date</p>
      <div class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width: 100%"
          v-model="offline.paidAt"
        />
      </div>
      <div class="mt-4">
        <c-select
          placeholder="--Select--"
          :options="banks"
          variant="w-full"
          label="Select Bank (Optional)"
          v-model="offline.paymentSource"
        />
      </div>
      <div class="mt-4">
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Transaction ID (Optional)"
          v-model="offline.paymentReferrenceId"
        />
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PensionDetails",
  components: {
    Breadcrumb: () =>
      import("@scelloo/cloudenly-ui/src/components/bread-crumb"),
    BackButton: () =>
      import("@scelloo/cloudenly-ui/src/components/back-button"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    CardFooter: () => import("@/components/CardFooter"),
    SearchInput: () =>
      import("@scelloo/cloudenly-ui/src/components/search-input"),
    STable: () => import("@scelloo/cloudenly-ui/src/components/table"),
    Badge: () => import("@scelloo/cloudenly-ui/src/components/badge"),
    Modal: () => import("@/components/Modal"),
    RightSideBar: () => import("@/components/RightSideBar"),
    Datepicker: () => import("vuejs-datepicker"),
    Button: () => import("@/components/Button"),
    Card: () => import("@/components/Card"),
    ProceedActionModal: () => import("@/components/ProceedActionModal"),
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Compliance",
          href: "PayRoll Pension",
          id: "pensions",
        },
        {
          disabled: false,
          text: "Pension",
          href: "ViewPension",
          id: "View Pension",
        },
        {
          disabled: false,
          text: this.$route.query.name,
          href: this.$route.query.name,
          id: "View Pension",
        },
      ],
      remitPayeDisplay: process.env.NODE_ENV === 'development',
      offline: {
        paidAt: null,
        paymentSource: "",
        paymentReferrenceId: "",
      },
      loading: true,
      showProcessModal: false,
      showRemitForm: false,
      isPayeOnlineRemit: false,

      payrunSummary: {
        payrunType: "",
        payCylce: "",
        payFrequency: "",
        paySchedule: "",
        payPosition: "",
        categories: "",
        dueDate: "",
        employeeNo: "",
        totalPAYE: "",
        payrollStatus: "",
      },

      payeData: {
        lirsName: "",
        taxNo: "",
        status: "",
      },

      employeesHeader: [
        { title: "Employee", value: "name" },
        { title: "EmployeE Tax ID Number", value: "taxId" },
        { title: "Calculated Annual PAYE", value: "annualPAYE" },
        { title: "Remitted To date", value: "remittedDate" },
        { title: "PAYE Balance", value: "payeBal" },
        { title: "Current PAYE", value: "currentPAYE" },
        { title: "Status", value: "status" },
      ],

      employeesData: [],
    };
  },

  methods: {
    async checkOption () {
      try {
        await this.$handlePrivilege("paye", "remitOnline");
        this.submit();
      } catch (error) {
        this.isPayeOnlineRemit = false;
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async submit(){
      const payload = {
        paidAt: this.payrunSummary.paidAt,
        paymentSource: "",
        paymentReferrenceId: "",
        payrunNo: this.$route.params.idNo,
        pfaId: this.$route.params.id
      }
      try{
        await this.$_remitPaye(payload)
        this.isPayeOnlineRemit = false;
        this.$toasted.success("Paye remitted successfully", {
          duration: 5000,
        });
      }catch(error){
        this.isPayeOnlineRemit = false;
        this.$toasted.error("Transaction not successful", {
          duration: 5000,
        });
      }
    },
    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    handleSubmit() {
      this.loading = true;
      this.showRemitForm = false;
      this.offline.payrunNo = this.$route.params.idNo;
      this.offline.pfaId = this.$route.params.id;
      this.$_payPayeOffline(this.offline)
        .then((response) => {
          this.$router.back();
          this.$toasted.success(response.data.message, {
            duration: 5000,
          });
        })
        .catch((err) => {
          this.loading = false;
          const message = err.message ? err.message : "failed.";
          this.$toasted.error(message, {
            duration: 5000,
          });
        });
    },
   async validateRemit() {
    try {
      await this.$handlePrivilege("paye", "remitOffline");
      if (this.payrunSummary.payrollStatus === 'paid') {
        this.showProcessModal = true
      }
      else {
        this.$toasted.error('Kindly pay associated pay run cycle to proceed with this', { duration: 5000 });
      }
    } catch (error) {
      this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
    }
    },
    proceed() {
      this.showProcessModal = false;
      this.showRemitForm = true;
    },

    pfaEmployees(id, taxNo) {
      this.$_getPAYEEmployees(taxNo, id)
        .then((result) => {
          const summary = result.data.payrunDetail;
          const payrunDetails = result.data.payrun;

          this.payeData.lirsName = result.data.taxAuthority.name;
          this.payeData.taxNo = result.data.taxAuthority.tin.tin;

          this.offline.sirs = result.data.sirs;

          this.payrunSummary.payrunType = summary.type;
          this.payrunSummary.payCylce = `${this.$DATEFORMAT(
            new Date(`${summary.year}-${summary.month}-01`),
            `MMMM yyyy`
          )} Cycle`;
          this.payrunSummary.payFrequency = summary.payFrequency;
          this.payrunSummary.paySchedule = "";
          this.payrunSummary.payPosition = summary.paySplitPosition;
          this.payrunSummary.paidAt = summary.paidAt;
          this.payrunSummary.dueDate =
            summary.payrollStatus === "paid"
              ? `${this.$DATEFORMAT(
                  new Date(`${summary.paidAt}`),
                  `MMMM dd, yyyy`
                )}`
              : `${this.$DATEFORMAT(
                  new Date(this.resetDate(`${summary.payDate}`)),
                  `MMMM dd, yyyy`
                )}`;
          this.payrunSummary.employeeNo = payrunDetails.length;
          this.payrunSummary.totalPAYE = summary.paye;
          this.payrunSummary.payrollStatus = summary.payrollStatus;

          this.employeesData = payrunDetails.map((detail) => ({
            id: detail.employee.userId,
            name: `${detail.fname} ${detail.lname}`,
            photo: detail.photo,
            designation: detail.userDesignation.name,
            taxId: detail.employee.employeeTaxId,
            annualPAYE: this.convertToCurrency(detail.annualPAYE),
            remittedDate: "---",
            payeBal: "---",
            currentPAYE: this.convertToCurrency(detail.monthlyPAYE),
            status: detail.payeStatus,
          }));

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({
          ...bank,
          id: bank.code,
        })),
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  mounted() {
    this.$store.dispatch("getBanks");
    this.pfaEmployees(this.$route.params.id, this.$route.params.idNo);
  },
};
</script>
